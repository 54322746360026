import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { isDesktop } from 'react-device-detect';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children, onClick }) => {
  const baseClasses = "cursor-pointer font-normal items-center justify-center flex px-6 w-[100px] h-[34px]";
  const hoverClasses = isDesktop ? "hover:text-[#dddddd]" : "";

  return (
    <Link
      to={to}
      className={`${baseClasses} ${hoverClasses}`}
      style={{
        fontSize: "12px",
        lineHeight: "16.8px",
        letterSpacing: "-1%",
        fontFamily: "ms300",
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

const Contact: React.FC = () => {
  const [limit, setLimit] = useState(true);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [orientation, setOrientation] = useState("");
  const [popup, setPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = (show: boolean) => {
    setShowPopup(show);
  };

  useEffect(() => {
    const handleResize = () => {
      const newHeight = window.innerHeight;
      const newWidth = window.innerWidth;
      setHeight(newHeight);
      setWidth(newWidth);
      const newOrientation = newWidth > newHeight ? "width" : "height";
      setOrientation(newOrientation);
      setLimit(!(newWidth < 600 && newOrientation === "width"));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={`flex flex-col items-center min-h-screen w-full overflow-hidden justify-center max-w-[2560px] mx-auto cursor-cr_def active:cursor-cr_hov`}
    >
      {!limit ? (
        <div className="flex items-center justify-center w-screen h-screen">
          <h1 className="fixed top-0 z-[100000]">
            {width}x{height}
          </h1>
          <div>디바이스를 세로로 돌려서 진행해주세요</div>
        </div>
      ) : (
        <div className="w-screen">
          <div>
            <h1 className="fixed top-0 z-[100000] text-blue-500 text-[10px]">
              
            </h1>
            <h3 className="fixed top-0 left-32 z-[100000] text-gray-500 text-[10px]">
              {width}x{height}
            </h3>

            <div
              id="header"
              className="fixed top-2 w-full h-16 hidden sm:block bg-white z-[9999]"
            >
              <div className="flex justify-between w-full max-w-[2560px] mx-auto">
                <NavLink to="/">BEODD</NavLink>
                <NavLink to="/about">ABOUT</NavLink>
                <NavLink to="/about"> </NavLink>
                <NavLink to="/interior">WORKS</NavLink>
                <NavLink to="/contact">CONTACT</NavLink>
              </div>
            </div>
            <div
              id="header"
              className={`fixed top-0 w-screen ${
                popup ? "h-screen" : ""
              } flex flex-col z-[100] sm:hidden bg-white`}
            >
              <div className="flex justify-center w-screen mt-[30px] items-center">
                <img
                  src={
                    popup
                      ? `/images/button_menu_open.png`
                      : `/images/button_menu_close.png`
                  }
                  width={40}
                  height={40}
                  alt=""
                  onClick={() => {
                    setPopup(!popup);
                  }}
                />
              </div>
              {popup ? (
                  <div className="w-screen flex flex-col items-center justify-around h-[75vh] bg-white -space-y-16 py-10">
                  <NavLink
                    to="/"
                    onClick={() => {
                      setTimeout(() => {
                        setPopup(false);
                      }, 500);
                    }}
                  >
                    BEODD
                  </NavLink>
                  <NavLink
                    to="/about"
                    onClick={() => {
                      setTimeout(() => {
                        setPopup(false);
                      }, 500);
                    }}
                  >
                    ABOUT
                  </NavLink>
                  <NavLink
                    to="/interior"
                    onClick={() => {
                      setTimeout(() => {
                        setPopup(false);
                      }, 500);
                    }}
                  >
                    WORKS
                  </NavLink>
                  <NavLink
                    to="/contact"
                    onClick={() => {
                      setTimeout(() => {
                        setPopup(false);
                      }, 500);
                    }}
                  >
                    CONTACT
                  </NavLink>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          
          <div className={`flex flex-col justify-center items-center h-[85vh] mt-10 sm:mt-0 space-y-5 sm:pb-6 pb-32`}>
            <div className="items-center justify-center w-full">
              <div
                className="text-center"
                style={{ fontFamily: "ms500", fontSize: "12px" }}
              >
                TEL
              </div>
              <div
                className="text-center"
                style={{ fontFamily: "ms300", fontSize: "12px" }}
              >
                02-798-8744
              </div>
            </div>
            <div className="items-center justify-center w-full">
              <div 
                className="text-center"
                style={{ fontFamily: "ms500", fontSize: "12px" }}
              >
                FAX
              </div>
              <div
                className="text-center"
                style={{ fontFamily: "ms300", fontSize: "12px" }}
              >
                02-798-8743
              </div>
            </div>
            <div className="items-center justify-center w-full">
              <div
                className="text-center"
                style={{ fontFamily: "ms500", fontSize: "12px" }}
              >
                EMAIL
              </div>
              <div
                className="text-center underline cursor-pointer"
                onClick={()=>{ window.open('mailto: info@beodd.kr');}}
                style={{ fontFamily: "ms300", fontSize: "12px" }}
              >
                INFO@BEODD.KR
              </div>
            </div>
            <div className="items-center justify-center w-full">
              <div
                className="text-center"
                style={{ fontFamily: "ms500", fontSize: "12px" }}
              >
                ADDRESS
              </div>
              <div
                className="text-center"
                style={{ fontFamily: "ms300", fontSize: "12px" }}
              >
                A-B1, 39-9
              </div>
              <div
                className="text-center"
                style={{ fontFamily: "ms300", fontSize: "12px" }}
              >
                HANGANG-DAERO 40-GIL
              </div>
              <div
                className="text-center"
                style={{ fontFamily: "ms300", fontSize: "12px" }}
              >
                YONGSAN-GU, SEOUL, KOREA
              </div>
            </div>
            <div className="items-center justify-center w-full">
              <div
                className="text-center"
                style={{ fontFamily: "ms500", fontSize: "12px" }}
              >
                INSTAGRAM
              </div>
              <div
                className="text-center underline cursor-pointer"
                style={{ fontFamily: "ms300", fontSize: "12px" }}
                onClick={()=>{ window.open('https://www.instagram.com/beodd_studio/', '_blank');}}
              >
                @BEODD STUDIO
              </div>
            </div>
            <div id="footer" className="fixed bottom-0 flex justify-end pb-[20px] items-center flex-col w-full h-16 bg-white z-50">
              <div className="font-normal uppercase cursor-default" style={{
                fontSize: "12px",
                lineHeight: "14.4px",
                letterSpacing: "-1%",
                fontFamily: "ms300",
              }}>
                copyrightⓒ2013-2024
              </div>
              <div className="font-normal uppercase cursor-default" style={{
                fontSize: "12px",
                lineHeight: "14.4px",
                letterSpacing: "-1%",
                fontFamily: "ms300",
              }}>
                design studio beodd all rights reserved  
              </div>
            </div> 
          </div>
        </div>
      )}
    </motion.div>
  )
}

export default Contact;