import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
// import { About } from "./pages/About";
import { About2 } from "./pages/About2";
import Contact from "./pages/Contact";
import InteriorImageViewer from "./pages/InteriorImageViewer";
import { Zzzz } from "./pages/Zzzz";
import Interior from "./pages/Interior";
import InteriorDetail from "./pages/InteriorDetail";
import Object from "./pages/Object";
import ObjectDetail from "./pages/ObjectDetail";
import Graphic from "./pages/Graphic";
import GraphicDetail from "./pages/GraphicDetail";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/zzzz" element={<Zzzz />} /> */}
        <Route path="/about" element={<Zzzz />} />
        <Route path="/interior" element={<Interior />} />
        <Route path="/graphic" element={<Graphic />} />
        <Route path="/object" element={<Object />} />
        <Route path="/interior-detail/:id" element={<InteriorDetail />} />
        <Route path="/graphic-detail/:id" element={<GraphicDetail />} />
        <Route path="/object-detail/:id" element={<ObjectDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/interior-image/:project/:id"
          element={<InteriorImageViewer />}
        />
        {/* <Route path="/interior-image/:id" element={<InteriorImageViewer />} />
      <Route path="/interior-image/:id" element={<InteriorImageViewer />} /> */}
      </Routes>
    </div>
  );
}

export default App;
