import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const InteriorImageViewer = () => {
  const { project, id } = useParams();

  useEffect(() => {
    console.log(project);
    console.log(id);
    document.body.style.margin = "0";
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.margin = "";
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className="w-screen h-screen bg-black">
      <img
        src={`/projects/interior/${project}/contents/mobile/o/${id}.webp`}
        alt={`Full screen ${project} image ${id}`}
        className="object-scale-down w-full h-full"
      />
    </div>
  );
};

export default InteriorImageViewer;
