import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { isAndroid, isDesktop } from "react-device-detect";

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children, onClick }) => {
  const baseClasses = "cursor-pointer font-normal items-center justify-center flex px-6 w-[100px] h-[34px]";
  const hoverClasses = isDesktop ? "hover:text-[#dddddd]" : "";

  return (
    <Link
      to={to}
      className={`${baseClasses} ${hoverClasses}`}
      style={{
        fontSize: "12px",
        lineHeight: "16.8px",
        letterSpacing: "-1%",
        fontFamily: "ms300",
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export const Zzzz: React.FC = () => {
  const [limit, setLimit] = useState<boolean>(true);
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [orientation, setOrientation] = useState<string>("");
  const [popup, setPopup] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showLogo, setShowLogo] = useState<boolean>(false);

  const togglePopup = (show: boolean): void => {
    setShowPopup(show);
    setShowLogo(show);
  };

  useEffect(() => {
    const handleResize = (): void => {
      const newHeight = window.innerHeight;
      const newWidth = window.innerWidth;
      setHeight(newHeight);
      setWidth(newWidth);
      const newOrientation = newWidth > newHeight ? "width" : "height";
      setOrientation(newOrientation);
      setLimit(!(newWidth < 600 && newOrientation === "width"));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderContent = () => {
    if (width >= 1367) {
      return (
        <div className="grid w-[40%]  xl:w-[40%] 2xl:w-[40%] 3xl:w-[50%] 4xl:w-[60%]  grid-cols-5 gap-4  max-w-[1280px]">
          {[...Array(15)].map((_, index) => (
            <div 
              key={index} 
              className="relative w-[100%] overflow-hidden aspect-square"
            >
              <img
                src={`/about/contents/${index}-1.webp`}
                alt={`Image ${index + 1}`}
                className="object-contain w-full h-full transition-opacity duration-300"
              />
              <img
                src={`/about/contents/${index}-2.webp`}
                alt={`Hover Image ${index + 1}`}
                className="absolute top-0 left-0 object-contain w-full h-full transition-opacity duration-300 opacity-0 hover:opacity-100"
              />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="w-[540px] md:w-[540px] sm:w-[320px] xs:w-[320px] xxs:w-[240px] xxxs:w-[240px]  max-w-[680px] aspect-square ">
          <img
            src="/about/contents/mobile.webp"
            alt="Single Image"
            className="object-cover w-full h-full"
          />
        </div>
      );
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={`flex flex-col items-center min-h-screen w-full overflow-hidden max-w-[2560px] mx-auto`}
    >
      {!limit ? (
        <div className="flex items-center justify-center w-screen h-screen">
          <h1 className="fixed top-0 z-[100000]">
            {width}x{height}
          </h1>
          <div>디바이스를 세로로 돌려서 진행해주세요</div>
        </div>
      ) : (
        <div className="w-screen">
          <div>
            <h1 className="fixed top-0 z-[100000] text-blue-500 text-[10px] bg-white">
              
            </h1>
            <h3 className="fixed top-0 left-32 z-[100000] text-gray-500 text-[10px] bg-white">
              {width}x{height}
            </h3>

            <div
              id="header"
              className="fixed pt-2 w-full h-16 hidden sm:block bg-white z-[9999]"
            >
              <div className="flex justify-between w-full max-w-[2560px] mx-auto">
                <NavLink to="/">BEODD</NavLink>
                <NavLink to="/about">ABOUT</NavLink>
                <NavLink to="/about"> </NavLink>
                <NavLink to="/interior">WORKS</NavLink>
                <NavLink to="/contact">CONTACT</NavLink>
              </div>
            </div>
            <div
              id="header"
              className={`fixed top-0 w-screen ${
                popup ? "h-screen" : ""
              } flex flex-col z-[100] sm:hidden bg-white`}
            >
              <div className="flex justify-center w-screen mt-[30px] items-center">
                <img
                  src={
                    popup
                      ? `/images/button_menu_open.png`
                      : `/images/button_menu_close.png`
                  }
                  width={40}
                  height={40}
                  alt=""
                  onClick={() => {
                    setPopup(!popup);
                  }}
                />
              </div>
              {popup && (
                <div className="w-screen flex flex-col items-center justify-around h-[75vh] bg-white -space-y-16 py-10">
                  <NavLink
                    to="/"
                    onClick={() => {
                      setTimeout(() => {
                        setPopup(false);
                      }, 500);
                    }}
                  >
                    BEODD
                  </NavLink>
                  <NavLink
                    to="/about"
                    onClick={() => {
                      setTimeout(() => {
                        setPopup(false);
                      }, 500);
                    }}
                  >
                    ABOUT
                  </NavLink>
                  <NavLink
                    to="/interior"
                    onClick={() => {
                      setTimeout(() => {
                        setPopup(false);
                      }, 500);
                    }}
                  >
                    WORKS
                  </NavLink>
                  <NavLink
                    to="/contact"
                    onClick={() => {
                      setTimeout(() => {
                        setPopup(false);
                      }, 500);
                    }}
                  >
                    CONTACT
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        
          <div className="flex flex-col items-center justify-center w-screen h-screen mt-10 cursor-cr_def active:cursor-cr_hov">
            <div className="flex flex-col items-center justify-start w-full max-w-[1920px] xxxs:-mt-36 xxs:-mt-36 xs:-mt-36  px-4">
              {showLogo ? (
                <div className="w-[300px] sm:w-[640px] md:w-[769px] lg:w-[769px] xl:w-[1080px] 3xl:w-[1080px] 4xl:w-[1280px] aspect-video">
                  <img
                    src="/about/contents/a/logo.gif"
                    alt="BEODD Logo"
                    className="object-contain w-full h-full"
                  />
                </div>
              ) : renderContent()}
              {!showPopup && (
                <div className="flex flex-col items-center" >
                  <div
                    className="uppercase font-semibold mt-[30px]"
                    style={{
                      fontSize: "12px",
                      lineHeight: "16.8px",
                      letterSpacing: "-0.5px",
                      fontFamily: "ms300",
                    }}
                  >
                    BEODD
                  </div>
                  <div
                    className="uppercase font-normal mt-[20px]"
                    style={{
                      fontSize: "12px",
                      lineHeight: "16.8px",
                      letterSpacing: "-0.6px",
                      fontWeight: "500",
                      fontFamily: "ms300",
                    }}
                  >
                    design studio
                  </div>
                  <div
                    className="font-normal uppercase"
                    style={{
                      fontSize: "12px",
                      lineHeight: "16.8px",
                      letterSpacing: "-0.6px",
                      fontWeight: "500",
                      fontFamily: "ms300",
                    }}
                  >
                    interior, product, construction, graphic
                  </div>
                  <div
                    className="font-normal uppercase"
                    style={{
                      fontSize: "12px",
                      lineHeight: "16.8px",
                      letterSpacing: "-0.6px",
                      fontWeight: "500",
                      fontFamily: "ms300",
                    }}
                  >
                    yongsan-gu, seoul, 2013-
                  </div>
                </div>
              )}
              <div className="space-x-14 fixed bottom-0 flex justify-center pb-[14px] px-5 w-full mdlg:justify-center mdlg:pb-0 mdlg:px-0 mdlg:static">
                <div
                  onClick={() => togglePopup(!showPopup)}
                  className={`uppercase font-normal ${showPopup ? "mt-[20px]" : "mt-[15px]"} underline cursor-pointer flex items-center justify-center text-[#fffff] px-10 py-2 ${isDesktop ? "hover:text-[#dddddd]" : ""}`}
                  style={{
                    fontSize: "12px",
                    lineHeight: "16.8px",
                    letterSpacing: "-0.6px",
                    fontWeight: "500",
                    fontFamily: "ms300",
                  }}
                >
                  {showPopup ? "CLOSE" : "LOGO"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};