import { atom } from "recoil";
import { Project } from "./interfaces/project.interface";


export const ShowPopupState = atom<boolean>({
  key: "ShowPopup",
  default: false,
});

export const ProjectsState = atom<Project[]>({
  key: "ProjectsState",
  default: [],
});
